import React from 'react'
import PropTypes from 'prop-types'

import { Card, FormReadOnlyLink, Row } from '@gk-devteam/apmc-core-web'

export default function ContractInfo ({ data }) {
  if (!data) return null

  return (
    <Card mb="L" title="yasue:construction.contract_info">
      <Row justify="start">
        <FormReadOnlyLink
          label='yasue:contracts.contract_manage_id'
          to={`/contracts/edit/${data?.contract_id}`}
          text={data?.contract_manage_id}
        />
        <FormReadOnlyLink
          label='navigation:app_users'
          to={`/app_users/edit/${data?.app_user_id}`}
          text={data?.app_user_name}
        />
      </Row>
    </Card>
  )
}

ContractInfo.propTypes = {
  data: PropTypes.object,
  contracts: PropTypes.array,
  contractID: PropTypes.string
}
