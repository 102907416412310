import React, { useEffect, useState } from 'react'
import Proptypes from 'prop-types'
import { CancelToken } from 'axios'
import { Select } from '@gk-devteam/apmc-core-web'

import { fetchContractList } from '../../../services'

export default function Contract ({ constructionWorkID, disabled }) {
  const [loading, setLoading] = useState(false)
  const [contracts, setContracts] = useState(null)

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      try {
        const cancelSource = CancelToken.source()
        const res = await fetchContractList(cancelSource)
        if (res && res.data) {
          const contractsOptions = res.data.results.map(contract => {
            const { id, contract_manage_id } = contract
            return {
              label: contract_manage_id,
              value: id,
              disabled: false
            }
          })
          setContracts(contractsOptions)
        }
        setLoading(false)
      } catch (error) {
        console.warning('error', error)
        setLoading(false)
      }
    }
    fetchData()
    return () => {
      // reset data
      setLoading(false)
      setContracts([])
    }
  }, [])

  return (
    <Select
      name="contract_id"
      label='yasue:contracts.contract_manage_id'
      flex={1}
      size="XL"
      options={contracts}
      loading={loading}
      disabled={constructionWorkID !== undefined || disabled}
      showSearch
      autocomplete="on"
      required
    />
  )
}

Contract.propTypes = {
  contracts: Proptypes.array,
  loading: Proptypes.bool,
  constructionWorkID: Proptypes.string,
  disabled: Proptypes.bool
}
