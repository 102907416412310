import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { ItemNotFound, LeftNavigationMenu, PageLayout, selectPages, useTranslation } from '@gk-devteam/apmc-core-web'

import { selectConstructionWorkError, selectLoadingConstructionWork } from '../../../selectors'
import { FETCH_CONSTRUCTION_WORK, RESET_CONSTRUCTION_WORK } from '../../../types'

import NotFoundPage from '../../../pages/NotFoundPage/NotFoundPage'

const ConstructionWorkBlock = ({ enabled, children, constructionWorkID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['navigation'])
  const pages = useSelector(selectPages)
  const loading = useSelector(selectLoadingConstructionWork)
  const error = useSelector(selectConstructionWorkError)

  useEffect(() => {
    if (constructionWorkID) {
      dispatch({ type: FETCH_CONSTRUCTION_WORK, id: constructionWorkID })
    }
    return () => {
      dispatch({ type: RESET_CONSTRUCTION_WORK })
    }
  }, [dispatch, constructionWorkID])

  if (!constructionWorkID) return null
  if (!enabled || !children || !pages) return <NotFoundPage />

  const getMenu = () => {
    const menu = []
    menu.push({
      label: t('navigation:yasue.construction_work_detail'),
      link: './'
    })
    menu.push({
      label: t('navigation:yasue.construction_work_files'),
      link: './files'
    })

    return menu
  }

  return (
    <PageLayout
      sidebar={loading || (error && error.code === 404) ? null : () => <LeftNavigationMenu menu={getMenu()}/>}
      noMain
    >
      {
        loading
          ? null
          : error && error.code === 404
            ? <ItemNotFound item={t('navigation:yasue.construction_work')} link="/yasue/construction_works?sort_by=contract_date&sort_order=desc" />
            : children
      }
    </PageLayout>
  )
}

export default ConstructionWorkBlock

ConstructionWorkBlock.propTypes = {
  enabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
  constructionWorkID: PropTypes.string
}
