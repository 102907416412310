import React, { useEffect, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import PropTypes from 'prop-types'
import {
  useTranslation,
  RESET_PARAMS,
  selectQueryParams,
  MainLayout,
  Table,
  dynamicLink
} from '@gk-devteam/apmc-core-web'

import {
  FETCH_ROOMS,
  RESET_ROOMS,
  DELETE_ROOM
} from '../../../types'
import {
  selectPropertyName,
  selectRoomsLoading,
  selectRoomsData,
  selectRoomsCount
} from '../../../selectors'

import RoomListHeader from './RoomListHeader'

const RoomsPage = ({ navigate, location, propertyID }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'rooms'])
  const query = useSelector(selectQueryParams)
  const propertyName = useSelector(selectPropertyName)
  const prevQuery = useRef(null)

  const fetchData = useCallback(
    () => {
      dispatch({ type: FETCH_ROOMS, propertyID, query })
    },
    [dispatch, propertyID, query]
  )

  useEffect(() => {
    if (prevQuery.current == null) {
      prevQuery.current = query
      fetchData()
    } else {
      if (!isEqual(prevQuery.current, query)) {
        prevQuery.current = query
        fetchData()
      }
    }
  }, [query, fetchData])

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ROOMS })
      dispatch({ type: RESET_PARAMS })
    }
  }, [dispatch, location.pathname])

  const breadcrumbs = [
    {
      label: 'navigation:properties',
      link: '/properties'
    },
    {
      text: propertyName || '',
      link: `/properties/edit/${propertyID}`
    },
    {
      label: 'navigation:rooms_list',
      link: `/properties/edit/${propertyID}/rooms`
    }
  ]

  const roomsColumns = [
    {
      title: t('properties:room_number'),
      dataIndex: 'room_number',
      key: 'room_number',
      cellTitle: true
      // sortable: true
    },
    {
      title: t('rooms:room_manage_id'),
      dataIndex: 'room_manage_id',
      key: 'room_manage_id'
    },
    {
      title: t('rooms:current_contractor'),
      dataIndex: 'contractor_name',
      key: 'contractor_name'
    },
    {
      title: t('rooms:current_resident'),
      dataIndex: 'resident_name',
      key: 'resident_name'
    },
    {
      title: ' ',
      dataIndex: '',
      key: 'option_menu',
      rowMenu: [
        {
          label: t('navigation:contracts_list'),
          onClick: (id, close, data) => {
            navigateToContracts(id, close, data)
          },
          shouldRender: 'contracts.enabled'
        },
        {
          label: t('delete'),
          danger: true,
          onClick: (id, close) => {
            console.log('warning', id)
            dispatch({ type: DELETE_ROOM, propertyID, roomID: id })
            close()
          },
          shouldRender: 'rooms.functions.delete'
        }
      ]
    }
  ]

  const _navigateToDetails = (id, row, e) => {
    const url = `/properties/edit/${propertyID}/rooms/edit/${id}`
    dynamicLink({
      url,
      event: e,
      navigate
    })
  }
  const navigateToContracts = useCallback(
    (id, close, data) => {
      close()
      navigate(`/contracts/?room_id=${id}`)
    },
    [navigate]
  )

  return (
    <MainLayout
      withSidebar={true}
      breadcrumbs={breadcrumbs}
    >
      <RoomListHeader propertyID={propertyID}/>
      <Table
        fullHeight
        columns={roomsColumns}
        rowKey="id"
        loadingSelector={selectRoomsLoading}
        resultsCountSelector={selectRoomsCount}
        dataSelector={selectRoomsData}
        onRowClick={_navigateToDetails}
      />
    </MainLayout>
  )
}
export default RoomsPage

RoomsPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  propertyID: PropTypes.string.isRequired
}
RoomsPage.defaultProps = {

}
